import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useShowStarterSunset } from "src/components/Account/useShowStarterSunset"
import { StarterSunsetBanner } from "src/components/AppLayout/Banners/StarterSunsetBanner"
import { EmptyState } from "src/components/EmptyState/EmptyState"
import { useFlagProvider } from "src/components/Flags/useFlagProvider"
import { HomeLog } from "src/components/HomeLog/HomeLog"
import { Guests } from "src/components/Homes/Guests/Guests"
import { HomeEventLog } from "src/components/Homes/HomeDetails/HomeEvents/HomeEventLog"
import { HomeMembers } from "src/components/Homes/HomeDetails/HomeMembers/HomeMembers"
import { HomeSettings } from "src/components/Homes/HomeDetails/HomeSettings"
import { AddVirtualDeviceButton } from "src/components/Homes/HomeDetails/Overview/AddVirtualDeviceButton"
import { Shortcodes } from "src/components/Homes/HomeDetails/Shortcodes/Shortcodes"
import { FeatureFlag } from "src/constants/featureFlags"
import { Violations } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHome,
  usePatchHome,
} from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFlags } from "src/hooks/useFlags"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { homeAccessHash } from "src/router/homeRoutes"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { Editable } from "src/ui/Editable/Editable"
import Svg404 from "src/ui/icons/404.svg"
import { MainView } from "src/ui/Layout/MainView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

import { HomeOverview } from "./Overview/HomeOverview"
import { ViolationBanner } from "./ViolationBanner"

export function HomeDetails() {
  const {
    virtualDevices: virtualDevicesEnabled,
    legacyEventLog,
    homeDetailsBeta,
  } = useFlags()

  const { org } = useOrganization()

  const { t } = useTranslate()
  const patchHome = usePatchHome()

  const { homeId } = useParams<{ homeId: string }>()

  const fetchHome = useFetchHome({
    orgId: org?.id,
    homeId,
  })
  const home = fetchHome.data

  const homeAccess = getAccessLogic({
    role: fetchHome.data?.user_role,
  })
  const userCanAccessHomeSettings = homeAccess.hasAdminAccess
  const userCanAccessShortcodes = homeAccess.hasAdminAccess
  const userCanChangeHomeConfig = homeAccess.hasAdminAccess

  const userCanEditGuests = homeAccess.hasAdminAccess

  async function onTitleSave(name: string) {
    if (!!org?.id) {
      await patchHome.mutateAsync({ orgId: org?.id, homeId, data: { name } })
    } else {
      throw new Error("Org ID is not set")
    }
  }

  if (fetchHome.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }
  const homeIsLoading = fetchHome.isLoading

  if (!home) {
    return <HomeLoadingState />
  }

  const tabs: ITab[] = [
    {
      id: "overview",
      label: langKeys.overview,
      view: (
        <HomeOverview
          home={home}
          key="overview"
          loading={homeIsLoading}
          editable={userCanChangeHomeConfig}
          toggleSecurityAlarmAllowed={homeAccess.hasMemberAccess}
        />
      ),
      placeholder: <OverviewPlaceholder />,
    },
    {
      hidden: !legacyEventLog,
      id: "log-legacy",
      label: "Log (Legacy)",
      view: (
        <div>
          <TopBox>
            <MText variant="heading2">{t(langKeys.log)}</MText>
          </TopBox>
          <HomeEventLog homeId={home.home_id} />
        </div>
      ),
      placeholder: null,
    },
    {
      id: "log",
      label: langKeys.log,
      view: (
        <div>
          <TopBox>
            <MText variant="heading2">{t(langKeys.log)}</MText>
          </TopBox>
          <HomeLog homeId={home.home_id} home={home} />
        </div>
      ),
      placeholder: null,
    },
    {
      id: Routes.HomeGuests.location(home.home_id).hash,
      label: langKeys.guest_plural,
      view: (
        <Guests
          homeId={home?.home_id}
          homeTz={home.timezone}
          canEdit={userCanEditGuests}
        />
      ),
      placeholder: null,
    },
    {
      id: homeAccessHash,
      label: langKeys.access_title,
      view: (
        <HomeMembers
          orgId={org?.id || ""}
          homeId={homeId}
          homeRole={home.user_role}
        />
      ),
      placeholder: null,
    },
    {
      id: Routes.HomeSettings.location(home.home_id).hash,
      label: langKeys.settings,
      view: !!home ? <HomeSettings key="home-settings" home={home} /> : null,
      placeholder: null,
      hidden: !userCanAccessHomeSettings,
    },
    {
      id: "shortcodes",
      label: langKeys.shortcodes,
      view: (
        <Shortcodes
          homeId={home.home_id}
          guestCommunication={home.guest_communication}
        />
      ),
      placeholder: null,
      hidden: !userCanAccessShortcodes,
    },
  ].filter((t) => !t?.hidden)

  return (
    <MainView
      banner={<Banner violations={home.violations} />}
      title={
        homeIsLoading ? (
          <MSkeleton width={350} height={48} />
        ) : (
          <Editable onSave={onTitleSave} disabled={!userCanChangeHomeConfig}>
            {home?.name}
          </Editable>
        )
      }
      metaTitle={home?.name}
      titleBarProps={{
        bottomMargin: "small",
        actionBar: (
          <StyledActionBar>
            {homeDetailsBeta && <HomeDetailsButton />}

            {virtualDevicesEnabled && (
              <AddVirtualDeviceButton homeId={home.home_id} />
            )}
          </StyledActionBar>
        ),
      }}
    >
      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.label}>
            {homeIsLoading ? tab.placeholder : tab.view}
          </StyledTabPanel>
        ))}
      </Tabs>
    </MainView>
  )
}

function OverviewPlaceholder() {
  return (
    <div style={{ display: "flex", marginBottom: spacing.XL }}>
      <MSkeleton
        variant="rect"
        height={190}
        width={190}
        style={{ borderRadius: "14px", marginRight: spacing.S }}
      />
      <MSkeleton
        variant="rect"
        height={190}
        width={190}
        style={{ borderRadius: "14px", marginRight: spacing.S }}
      />
      <MSkeleton
        variant="rect"
        height={400}
        style={{ borderRadius: "14px", flexGrow: 1 }}
      />
    </div>
  )
}

function HomeLoadingState() {
  return (
    <HomeLoadingStateBox>
      <MSkeleton width="30ch" />
      <MSkeleton />
    </HomeLoadingStateBox>
  )
}

function HomeDetailsButton() {
  const { setFeature } = useFlagProvider()
  const { showNewHomeDetailsView } = useFlags()

  return (
    <MButton
      variant="minimal"
      onClick={() =>
        setFeature(
          FeatureFlag.SHOW_NEW_HOME_DETAILS_VIEW,
          !showNewHomeDetailsView
        )
      }
    >
      Switch to new version
    </MButton>
  )
}

const HomeLoadingStateBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`

const StyledActionBar = styled.div`
  display: flex;
  gap: ${spacing.S};
`

const TopBox = styled.div`
  margin-bottom: ${spacing.M};
`

function Banner({ violations }: { violations: Violations[] | undefined }) {
  const { showStarterSunset } = useShowStarterSunset()

  const bannerItems: { visible: boolean; component: React.ReactNode }[] = [
    {
      component: (
        <ViolationBanner violations={violations} key="violations-banner" />
      ),
      visible: !!violations?.length,
    },
    {
      component: <StarterSunsetBanner key="starter-sunset-banner" />,
      visible: showStarterSunset,
    },
  ].filter((b) => b.visible)

  if (!bannerItems.length) {
    return null
  }

  return <BannerItemsBox>{bannerItems.map((b) => b.component)}</BannerItemsBox>
}

const BannerItemsBox = styled.div`
  display: grid;
  gap: ${spacing.M};
  margin-bottom: ${spacing.XL2};
`
