import { useMemo, useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay } from "date-fns"

import { HomeLogEmptyState } from "src/components/HomeLog/HomeLogEmptyState"
import { HomeLogEventFilterDropdown } from "src/components/HomeLog/HomeLogEventFilterDropdown"
import { HomeLogTable } from "src/components/HomeLog/HomeLogTable"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { mapFilterGroupsToHomeEventTypes } from "src/data/events/logic/eventsLogic"
import { useFetchHomeEvents } from "src/data/events/queries/homeEventQueries"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { spacing } from "src/ui/spacing"

type HomeLogProps = {
  homeId: string
  home: THome
}

export function HomeLog({ homeId, home }: HomeLogProps) {
  const { limit, offset, setOffset } = useUrlPager({ initialLimit: 20 })
  const [eventFilter, setEventFilter] = useState<THomeEventFilterGroup[]>([])

  const [dateRange, setDateRange] = useState<{
    startDate: Date | null
    endDate: Date | null
  }>({
    startDate: null,
    endDate: null,
  })

  const eventTypeFilter = useMemo(() => {
    return mapFilterGroupsToHomeEventTypes(eventFilter)
  }, [eventFilter])

  const fetchHomeEvents = useFetchHomeEvents({
    homeId,
    params: {
      limit,
      offset,
      event_type: eventTypeFilter,
      start_at: !!dateRange.startDate
        ? startOfDay(dateRange.startDate).toISOString()
        : undefined,
      end_at: !!dateRange.endDate
        ? endOfDay(dateRange.endDate).toISOString()
        : undefined,
    },
  })
  const homeEvents = fetchHomeEvents.data?.home_events || []
  const totalCount = fetchHomeEvents.data?.paging.total_count || 0
  const hasNoEvents = !homeEvents.length && !fetchHomeEvents.isInitialLoading
  const isFiltered =
    eventTypeFilter.length > 0 || !!dateRange.startDate || !!dateRange.endDate
  // NB: If the user sets a date range it cannot be cleared by the user so
  // isFiltered will always be true; this will need to be fixed witin the
  // DateRangePicker component though it's probably not worth the effort.

  return (
    <div>
      <FilterBox>
        <HomeLogEventFilterDropdown
          loading={fetchHomeEvents.isLoading}
          eventFilter={eventFilter}
          setEventFilter={(filter) => {
            setOffset(0)
            setEventFilter(filter)
          }}
        />

        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDateRangeChange={(dateRange) => {
            setOffset(0)
            setDateRange(dateRange)
          }}
          dateRangeProps={{ minBookingDays: 2 }}
        />
      </FilterBox>

      <MSpacer />

      <HomeLogTable
        homeEvents={homeEvents}
        loading={fetchHomeEvents.isLoading}
        home={home}
      />
      {hasNoEvents && <HomeLogEmptyState isFiltered={isFiltered} />}

      <XLSpacer />

      <Pager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
      />
    </div>
  )
}

const MSpacer = styled.div`
  margin-top: ${spacing.M};
`

const XLSpacer = styled.div`
  margin-top: ${spacing.XL};
`

const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.M};
  margin-bottom: ${spacing.M};
`
